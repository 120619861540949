* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
html,
body {
  // overflow: hidden;
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', 'SF Pro KR', 'SF Pro Text', 'SF Pro Icons', 'Apple Gothic', 'HY Gulim', 'MalgunGothic',
    'HY Dotum', 'Lexi Gulim', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
a {
  color: #111;
  text-decoration: none;
  list-style: none;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
*:focus {
  outline: 0;
}
button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: 0px;
  background-color: #fff;
  border: 0;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.hide {
  display: none;
}

.transition-layer {
  // position: fixed;
  // left: 0;
  // width: 100vw;
  // height: 100vh;
  // bottom: 0;
  // top: 0;
  // background-color: #f8f8f8;
  // transition: clip 0.7s ease;
  // z-index: 8888;
  // will-change: clip;
  // &.transition {
  //   &-enter {
  //     clip: rect(100vh, 100vw, 100vh, 0vw);
  //   }
  //   &-enter-active {
  //     clip: rect(0vh, 100vw, 100vh, 0vw);
  //   }
  //   &-enter-done {
  //     clip: rect(0vh, 100vw, 0vh, 0vw);
  //   }
  //   &-exit {
  //     clip: rect(100vh, 100vw, 100vh, 0vw);
  //   }
  //   &-exit-active {
  //     clip: rect(0vh, 100vw, 100vh, 0vw);
  //   }
  //   &-exit-done {
  //     clip: rect(0vh, 100vw, 100vh, 0vw);
  //   }
  // }
}
#root {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
.frame {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
.wrap {
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  // overflow: scroll;
  // -webkit-overflow-scrolling: touch;
  &.frame {
    &-enter {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &-enter-active {
      opacity: 1;
      transition-delay: 0.5s;
    }
    &-exit {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    &-exit-active {
      opacity: 0;
    }
  }
}
.empty-wrap {
  text-align: center;
  margin-top: 150px;
  @media screen and(max-width:$tablet) {
    margin-top: 60px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.63;
    color: #999999;
    @media screen and(max-width:$tablet) {
      font-size: 13px;
    }
  }
  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.63;
    color: #999999;
    font-weight: bold;
    @media screen and(max-width:$tablet) {
      font-size: 13px;
    }
  }
}
.layout-page {
  min-height: 100%;
}
.guide {
  position: absolute;
  top: 0;
  // top: -1197px;
  left: 0;
  width: 100vw;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  img {
    display: block;
    width: 100%;
    &.pc {
      width: 1200px;
      margin: 0 auto;
    }
  }
}
