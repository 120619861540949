.popup-dim {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9910;
  background-color: rgba(0,0,0,0.3);
  @media screen and (max-width: $mobile) {
    display: flex;
  }

  .popup-desktop {
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  .popup-mobile {
    display: none;
    @media screen and (max-width: $mobile) {
      display: block;
    }
  }

  .popup-container {
    padding: 0;
    margin-right: 160px;
    margin-top: 115px;
    text-align: right;
    float: right;

    @media screen and (max-width: $mobile) {
      margin: 0;
      padding: 54px 30px;
      width: 100%;
      max-height: 100vh;
      float: none;
      display: flex;
      align-items: center;
    }
  }

  .popup-close {
    color: white;
    font-size: 45px;
    line-height: 45px;
    cursor: pointer;
    display: inline-block;

    @media screen and (max-width: $mobile) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  .popup-body {
    clear: both;
    border-radius: 4px;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }

    & img {
      @media screen and (max-width: $mobile) {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
      }
    }
  }

  .popup-close-until {
    text-align: center;
    margin-top: 16px;
    color: white;
    font-weight: bold;
    cursor: pointer;

    &.mobile {
      float: left;
      margin-top: 10px;
    }
  }
}
