.background-loading {
  background: -moz-linear-gradient(left, #f7f7f7 0%, #edeef1 40%, #edeef1 60%, #f7f7f7 100%);
  background: -webkit-linear-gradient(left, #f7f7f7 0%, #edeef1 40%, #edeef1 60%, #f7f7f7 100%);
  background: linear-gradient(to right, #f7f7f7 0%, #edeef1 40%, #edeef1 60%, #f7f7f7 100%);
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-size: 350px auto;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-name: post-loading-mobile;
  -webkit-animation-name: post-loading-mobile;
  &.news {
    min-height: 350px;
    &.load {
      min-height: auto;
    }
  }
  &.main {
    animation-name: post-loading-mobile;
    -webkit-animation-name: post-loading-mobile;
    background-size: 660px auto;
    &.load {
      .item {
        opacity: 1;
      }
    }
  }
  &.load {
    animation: none;
    background: none;
    img,
    .image {
      opacity: 1;
      background-color: #f7f7f7;
    }
  }
  img,
  .image {
    opacity: 0;
    transition: opacity 0.4s ease;
  }
}
.main-loading-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
  z-index: 9900;
  overflow: hidden;
  &.loading-box {
    &-exit {
      background-color: #ffffff;
      transition: background-color 0.5s ease;
      .text-wrap {
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
    &-exit-active {
      background-color: transparent;
      .text-wrap {
        opacity: 0;
      }
    }
  }
  svg {
    position: absolute;
    fill: none;
  }
  .logo {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: auto;
    transform: translate3d(0px, 0, 0) scale(0.5);
    transition: all 0.4s ease;
    @media screen and(max-width:$tablet) {
      transform: scale(0.35);
    }
    &.load {
      transition-delay: 0.6s;
      transform: translate3d(-110px, 0, 0) scale(0.35);
      @media screen and(max-width:$tablet) {
        transform: translate3d(-110px, 0, 0) scale(0.25);
      }
    }
  }
  .progress {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #060101;
    -webkit-clip-path: url(#shape);
    clip-path: url(#shape);
    -webkit-transform: translateZ(1px);
    transition: width 0.4s ease;
  }
  .title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 250px;
    height: 20px;
    background-position: left center;
    background-size: 230px;
    background-repeat: no-repeat;
    background-image: url(../../images/logo-text.svg);
    transform: translate3d(55px, 0, 0);
    opacity: 0;
    @media screen and(max-width:$tablet) {
      background-size: 200px;
      transform: translate3d(45px, 0, 0);
    }
    &.load {
      opacity: 1;
      transition: all 0.4s ease;
      transition-delay: 0.7s;
    }
  }
}
@keyframes post-loading-mobile {
  0% {
    background-position: -350px 0;
  }

  100% {
    background-position: 350px 0;
  }
}
@keyframes post-loading-mobile {
  0% {
    background-position: -660px 0;
  }

  100% {
    background-position: 660px 0;
  }
}
