.cursor-wrapper {
  position: fixed;
  top: 0;
  z-index: 9800;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 50%;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &.CURSOR_MENU {
  }
  &.CURSOR_MOVE {
    .cursor {
      text-align: center;
      span {
        color: #fff;
      }
    }
  }
  .cursor {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    cursor: pointer;
  }
  &.load {
    opacity: 1;
    &.detail {
    }
  }
  &.detail {
  }
}
