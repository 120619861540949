.contact-container {
  padding: 10px 15px 0;

  .dic {
    margin-top: 30px;
    @media screen and (max-width: $mobile) {
      margin-top: 20px;
    }
    h4 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #222222;
      @media screen and (max-width: $mobile) {
        font-size: 14px;
        line-height: 26px;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.63;
      margin-top: 6px;
      letter-spacing: normal;
      text-align: center;
      color: #222222;
      @media screen and (max-width: $mobile) {
        font-size: 14px;
        padding: 0 40px;
      }
    }
  }
}
.map-container {
  margin: 40px auto 0;
  text-align: center;
  @media screen and (max-width: $mobile) {
    margin: 20px auto 0;
  }
  iframe {
    display: inline-block;
    width: 100%;
    max-width: 700px;
    height: 240px;
    @media screen and (max-width: $mobile) {
      height: 130px;
    }
  }
}
