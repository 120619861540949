.about {
  margin-top: 180px;
  @media screen and(max-width:$tablet) {
    margin-top: 60px;
  }
  .contact-section {
    margin: 0 0 150px 0;
    @media screen and(max-width:$tablet) {
      margin: 0 0 60px 0;
    }
    .title {
      font-size: 60px;
      font-weight: 800;
      text-align: center;
      color: #222222;
      @media screen and(max-width:$tablet) {
        font-size: 30px;
      }
    }
  }
  .image-wrap {
    margin-top: 95px;
    width: 100vw;
    height: 400px;
    @media screen and(max-width:$tablet) {
      margin-top: 40px;
      height: 150px;
    }
    .background-loading {
      width: 100%;
      height: 100%;
    }
    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }
  .introduce {
    margin: 90px auto 0;
    max-width: 760px;
    padding: 0 30px;
    font-size: 18px;
    line-height: 34px;
    @media screen and(max-width:$tablet) {
      margin-top: 40px;
      font-size: 14px;
    }
  }
}
