@keyframes main-image-width {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes main-image-height {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
