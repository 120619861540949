.detail-container {
  .detail-wrap {
    position: relative;
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    padding: 180px 40px 0;
    @media screen and (max-width: $tablet) {
      position: relative;
      display: block;
      padding: 100% 0 0;
    }
  }
  .other-models-container {
    background-color: #f9f9f9;
    margin-top: 90px;
    @media screen and(max-width:$tablet) {
      margin-top: 40px;
    }
  }
  .other-models-wrap {
    max-width: 1280px;
    padding: 90px 40px 150px;
    margin: 0 auto;
    @media screen and(max-width:$tablet) {
      padding: 40px 16px 0px;
    }
    .title {
      font-size: 30px;
      font-weight: 800;
      line-height: 1.13;
      color: #222222;
      @media screen and(max-width:$tablet) {
        font-size: 18px;
      }
    }
    .profile-wrap {
      margin-top: 40px;
      @media screen and(max-width:$tablet) {
        margin: 40px 0 0px 0;
        padding-bottom: 40px;
      }
    }
  }
  .detail-profile-wrap {
    flex: 1;
    .sticky-content {
      position: -webkit-sticky;
      position: sticky;
      top: 110px;
      @media screen and(max-width:$tablet) {
        position: relative;
        top: 0;
      }
    }
    @media screen and (max-width: $tablet) {
      margin-top: 22px;
      padding: 0 16px;
    }
    .title {
      font-size: 60px;
      font-weight: 800;
      color: #222222;
      @media screen and (max-width: $desk) {
        font-size: 42px;
      }
      @media screen and (max-width: $tablet) {
        font-size: 30px;
      }
    }
    .status {
      display: flex;
      align-items: center;
      margin-top: 12px;
      @media screen and (max-width: $tablet) {
        margin-top: 8px;
      }
      .national {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background-position: center;
        border-radius: 50%;
        background-color: #f5f5f5;
        box-sizing: border-box;
        overflow: hidden;
        background-size: 100% auto;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid rgba($color: #000000, $alpha: 0.05);
          box-sizing: border-box;
          border-radius: 50%;
        }
      }
    }

    .social-link {
      margin-top: 60px;
      display: flex;
      align-items: center;
      @media screen and (max-width: $tablet) {
        margin: 29px 0 0;
        text-align: right;
        justify-content: flex-end;
      }
      a {
        position: relative;
        display: inline-block;
        border-radius: 30px;
        border: solid 1px #222222;
        font-size: 12px;
        font-weight: 500;
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 0.65;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          color: #222222;
        }
        .icon {
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
      .portfolio {
        display: flex;
        align-items: center;
        padding: 0 15px 0 36px;
        height: 34px;
        .icon {
          position: absolute;
          display: inline-block;
          width: 16px;
          height: 16px;
          top: 0px;
          bottom: 0px;
          left: 14px;
          margin: auto 0;
          background-image: url('../../images/modellist-icn-portfolio.svg');
        }
      }
      .instagram {
        display: flex;
        align-items: center;
        padding: 0 15px 0 36px;
        height: 34px;
        margin-left: 8px;
        .icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 14px;
          margin: auto;
          width: 16px;
          height: 16px;
          background-image: url('../../images/modellist-icn-instagram.svg');
        }
      }
    }
  }
  .detail-main {
    position: absolute;
    top: 180px;
    right: 0px;
    padding-right: 40px;
    width: 50%;
    max-width: 630px;
    z-index: 10;
    .background-loading {
      position: relative;
      padding-top: 100%;
    }
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      cursor: pointer;
    }
    @media screen and (max-width: $tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      padding: 0;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 170px;
        opacity: 0.5;
        background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
        z-index: 10;
      }
    }
  }
  .image-container {
    position: relative;
    width: 50%;
    @media screen and(max-width:$tablet) {
      width: 100%;
    }
  }
  .image-wrap {
    max-width: 590px;
    min-height: auto;
    width: 100%;
    padding-top: 100%;
    margin: 0 0 0 auto;
    .background-loading {
      margin-top: 30px;
      & + .background-loading {
        margin-top: 30px;
        @media screen and (max-width: $tablet) {
          margin-top: 15px;
        }
      }
    }
    @media screen and (max-width: $tablet) {
      max-width: 100%;
      padding: 0;
      margin: 40px 0 0;
      min-height: 0;
    }

    img {
      width: 100%;
    }
  }
  .detail-info-box {
    display: flex;
    margin-top: 60px;
    @media screen and (max-width: $tablet) {
      margin-top: 27px;
    }
    .info-row {
      display: inline-flex;
      & + .info-row {
        margin-left: 70px;
        @media screen and (max-width: $desk) {
          margin-left: 40px;
        }
        @media screen and (max-width: $tablet) {
          margin-left: 50px;
        }
        @media screen and (max-width: $mobile) {
          margin-left: 31px;
        }
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      & + .info {
        margin-left: 30px;
        @media screen and (max-width: $desk) {
          margin-left: 20px;
        }
        @media screen and (max-width: $tablet) {
          margin-left: 21px;
        }
      }
      .tit {
        font-size: 14px;
        font-weight: 800;
        white-space: nowrap;
        color: #222222;
        line-height: 2.43;
        @media screen and (max-width: $tablet) {
          font-size: 13px;
          line-height: 2.5;
        }
      }
      .content {
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        line-height: 2.43;
        @media screen and (max-width: $tablet) {
          font-size: 13px;
          line-height: 2.5;
        }
      }
    }
  }
}
