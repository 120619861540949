.badge {
  position: relative;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 23px;
  border-radius: 2px;
  background-color: #f5f5f5;
  .ic-badge {
    position: absolute;
    left: 8px;
    top: 9px;
    display: inline-block;
    width: 11px;
    height: 11px;
  }
  .text {
    font-size: 12px;
    font-weight: 600;
    color: #999999;
  }
  &.inTown {
    .ic-badge {
      background-image: url('./intown_gray.svg');
    }
  }
  &.coming {
    .ic-badge {
      background-image: url('./coming_gray.svg');
    }
  }
  &.outOfTown {
    .ic-badge {
      background-image: url('./outoftown_gray.svg');
    }
  }
}
