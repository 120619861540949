@import './home_animation';
.main-section {
  position: relative;
  cursor: pointer;
  @media screen and(max-width:$tablet) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      opacity: 0.2;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
    &:before {
      width: 0;
      height: 0;
      left: 15px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 10px solid #000000;
    }
    &:after {
      width: 0;
      height: 0;
      right: 15px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 10px solid #000000;
    }
  }
}
.main-image-container {
  position: relative;
  margin: 162px auto 0;
  right: 48px;
  @media screen and (max-width: $mobile) {
    margin: 135px auto 0;
  }
  &.intro {
    .main-image {
      width: 100%;
      &-enter {
        position: absolute;
        opacity: 1;
        width: 0;
        transition: all 0.8s $easeOut;
        transition-delay: 0.2s;
      }
      &-enter-active {
        opacity: 1;
        width: 100%;
      }
      &-exit {
        position: absolute;
        opacity: 1;
        width: 100%;
        transition: all 0.2s ease;
      }
      &-exit-active {
        opacity: 0;
      }
    }
    .banner-box-wrap {
      height: 100%;
      .title,
      h2 {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      &.banner-box {
        &-enter {
          height: 0;
          transition: height 0.8s $easeOut;
          transition-delay: 0.5s;
          .title,
          h2 {
            opacity: 0;
            // transform: translate3d(0, -50px, 0);
            transition: opacity 0.4s ease, transform 0.4s ease;
            transition-delay: 0.7s;
          }
        }
        &-enter-active {
          height: 100%;
          .title,
          h2 {
            opacity: 1;
            transform: translate3d(0, 0px, 0);
          }
        }

        &-exit {
          opacity: 1;
          transition: opacity 0.2s;
        }
        &-exit-active {
          height: 100%;
          opacity: 0;
        }
      }
    }
    .banner-profile-wrap {
      width: 100%;
      &.banner-profile {
        &-enter {
          position: absolute;
          opacity: 1;
          width: 0;
          transition: all 0.8s $easeOut;
          transition-delay: 0.2s;
        }
        &-enter-active {
          opacity: 1;
          width: 100%;
        }
        &-exit {
          position: absolute;
          opacity: 1;
          width: 100%;
          transition: all 0.2s ease;
        }
        &-exit-active {
          opacity: 0;
        }
      }
    }
  }
  .navigator > div {
    position: absolute;
    top: 50%;
    z-index: 1000;
    width: 70px;
    height: 70px;
    transform: translate3d(0, -50%, 0);
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
    &.btn-prev {
      left: -220px;
    }
    &.btn-next {
      right: -316px;
    }
  }
}
.main-image-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.main-image-wrap > div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: calc(50% - 250px);
  will-change: transform;
}
.main-image-wrap .item {
  position: absolute;
  top: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 500px;
  height: 500px;
  will-change: transform;
  background-color: #fff;
  opacity: 0;
  transition: all 0.3s ease;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  &:hover {
    // transform: scale(1.05);
  }
}

.banner-box-container {
  position: absolute;
  bottom: 224px;
  z-index: 1000;
  right: -96px;
  left: 0;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.banner-box-wrap {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  overflow-y: hidden;
  transition: height 1s $easeOut;
  transition-delay: 0.3s;
  .title,
  h2 {
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.4s ease;
    transition-delay: 0.7s;
    // transform: translate3d(0, -50px, 0);
  }
  .static {
    position: static;
    display: flex;
    justify-content: center;
  }
  .title {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    z-index: 10;
    white-space: nowrap;
  }

  .banner-box {
    position: absolute;
    bottom: 0;
    color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #000;
    display: flex;
    align-items: center;
    cursor: default;
    h2 {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      white-space: nowrap;
      font-size: 56px;
      font-weight: 800;
      overflow: hidden;
    }
  }
}
.banner-profile-container {
  position: absolute;
  margin-top: 96px;
  right: -96px;
  cursor: pointer;
}
.banner-profile-wrap {
  position: absolute;
  width: 0;
  height: 100%;
  z-index: 800;
  left: 0;
  overflow: hidden;

  transition: all 1s $easeOut;
  .background-loading {
    position: static;
    .image {
      position: absolute;
      left: 0;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}

.main-image {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  width: 0;
  transition: all 1s $easeOut;
  .background-loading {
    position: static;
  }
}
.mobile {
  .btn-more {
    &:hover {
      border: solid 1px #ededed;
      color: #555555;
    }
  }
}
.btn-more {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 230px;
  height: 60px;
  border: solid 1px #ededed;
  font-size: 16px;
  font-weight: 500;
  color: #555555;
  margin: 134px auto 0;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    color: #222;
    border-color: #222;
  }
  @media screen and (max-width: $mobile) {
    margin: 72px auto 0;
    font-size: 12px;
    max-width: 140px;
    height: 40px;
  }
}

.contact-section {
  margin: 230px 0 230px;
  @media screen and (max-width: $mobile) {
    margin: 70px 0 70px;
  }
  .title {
    font-size: 38px;
    line-height: 47px;
    font-weight: 800;
    text-align: center;
    color: #222;
    margin: 0;
    @media screen and (max-width: $mobile) {
      font-size: 20px;
    }
  }
}
