.error-container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translate3d(0, -50%, 0);
  p {
    color: #999;
    font-size: 16px;
    line-height: 1.6;
  }
  a {
    width: 220px;
    height: 60px;
    border: solid 1px #ededed;
    font-size: 16px;
    font-weight: 500;
    color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0;
  }
}
