.footer-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 40px;
  background-color: #ededed;
  overflow: hidden;
  @media screen and (max-width: $tablet) {
    padding: 30px 0;
    height: auto;
    text-align: center;
    display: block;
  }
  .footer-left {
    display: flex;
    flex: 1 1;
    justify-self: flex-end;
    text-align: left;
    align-items: center;
    @media screen and (max-width: $tablet) {
      display: block;
      text-align: center;
      justify-content: center;
    }
    .logo {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url('../images/footer-logo.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 0;
      @media screen and (max-width: $tablet) {
        display: block;
        margin: 0 auto;
        width: 28px;
        height: 28px;
      }
    }
    a {
      margin-left: 12px;
      font-size: 14px;
      line-height: 1.71;
      color: #999999;
      @media screen and (max-width: $tablet) {
        display: block;
        margin: 12px 0 12px;
      }
    }
  }
  .footer-right {
    display: flex;
    flex: 1 1;
    justify-self: flex-end;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: $tablet) {
      justify-content: center;
    }
    a {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-size: 100%;
      background-repeat: no-repeat;
      @media screen and (max-width: $tablet) {
        width: 17px;
        height: 17px;
      }
      &.instagram {
        background-image: url('../images/footer-icn-instagram.svg');
      }
      &.twitter {
        background-image: url('../images/footer-icn-twitter.svg');
      }
      &.facebook {
        background-image: url('../images/footer-icn-facebook.svg');
      }
      &.youtube {
        background-image: url('../images/footer-icn-youtube.svg');
      }
      & + a {
        margin-left: 21px;
      }
    }
  }
}
