.profile-section {
  max-width: 1280px;
  margin: 0 auto;
  padding: 320px 40px 0;
  @media screen and(max-width:$desk) {
    padding: 320px 40px 0;
  }
  @media screen and(max-width:$tablet) {
    padding: 220px 16px 0;
  }
  &.detail {
    padding-top: 180px;
    padding-bottom: 150px;
    min-height: calc(100vh - 100px);
    @media screen and(max-width:$tablet) {
      padding-top: 75px;
      padding-bottom: 0;
    }
    > .title {
      font-size: 60px;
      line-height: 1.23;
      @media screen and(max-width:$tablet) {
        font-size: 30px;
      }
    }
  }

  > .title {
    font-size: 46px;
    font-weight: 800;
    text-align: center;
    color: #222;
    margin: 0;
    @media screen and(max-width:$tablet) {
      font-size: 20px;
    }
  }
}
.detail .profile-wrap {
  grid-gap: 100px 20px;
  margin-top: 95px;
  @media screen and (max-width: $tablet) {
    grid-gap: 20px 9px;
    margin: 40px 0 60px 0;
  }
}
.profile-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 38px 20px;
  justify-content: space-between;
  margin-top: 84px;

  @media screen and (max-width: $desk) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px 20px;
    margin-top: 40px;
  }
  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 9px;
  }
  @media screen and (max-width: 620px) {
    grid-template-columns: 1fr 1fr;
  }
  .profile-card {
    position: relative;
    width: 100%;
    will-change: transform;

    > div {
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.4s ease;
    }
    &.active {
      > div {
        opacity: 1;
      }
    }
    .background-loading {
      overflow: hidden;
      cursor: pointer;
      .image,
      img {
        transition: transform 0.5s $easeOut;
        transform: scale(1);
      }
      &:hover {
        .image,
        img {
          transform: scale(1.1);
        }
      }
    }
    .info-box > .title {
      display: flex;
      align-items: center;
      margin: 15px 0 6px 0;
      @media screen and (max-width: $desk) {
        margin: 10px 0 4px 0;
      }
      @media screen and (max-width: $tablet) {
        margin: 8px 0 2px 0;
      }
      h3 {
        font-size: 26px;
        line-height: 1.25;
        font-weight: 900;
        margin: 0;
        @media screen and (max-width: $desk) {
          font-size: 20px;
        }
        @media screen and (max-width: $tablet) {
          font-size: 18px;
        }
        @media screen and (max-width: $mobile) {
          font-size: 14px;
        }
      }
    }
    .national {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-position: center;
      border-radius: 50%;
      background-color: #f5f5f5;
      box-sizing: border-box;
      overflow: hidden;
      background-size: 100% auto;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid rgba($color: #000000, $alpha: 0.05);
        box-sizing: border-box;
        border-radius: 50%;
      }
      @media screen and (max-width: $tablet) {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
    &.main {
      position: relative;

      &:nth-child(2n) {
        top: 80px;
        @media screen and (max-width: $desk) {
          top: 0px;
        }
      }
      &:nth-child(3n + 2) {
        @media screen and (max-width: $desk) {
          top: 50px;
        }
        @media screen and (max-width: 620px) {
          top: 0px;
        }
      }
      &:nth-child(2n) {
        @media screen and (max-width: 620px) {
          top: 30px;
        }
      }
    }

    .background-loading {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
    }
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    .badge {
      background-color: transparent;
      padding: 0;
      height: auto;
      display: flex;
      align-items: center;
      &.inTown {
        .ic-badge {
          background-image: url('../../components/Status/intown_black.svg');
        }
      }
      &.coming {
        .ic-badge {
          background-image: url('../../components/Status/coming_black.svg');
        }
      }
      &.outOfTown {
        .ic-badge {
          background-image: url('../../components/Status/outoftown_black.svg');
        }
      }
      .ic-badge {
        position: relative;
        left: 0;
        top: 0;
        width: 12px;
        height: 12px;
        margin-right: 4px;
        background-size: 100%;
        @media screen and (max-width: $tablet) {
          width: 10px;
          height: 10px;
        }
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: #555555;
        @media screen and (max-width: $tablet) {
          font-size: 12px;
        }
        @media screen and (max-width: $mobile) {
          font-size: 11px;
        }
      }
    }
    .contents {
      margin-top: 24px;
      @media screen and(max-width:$tablet) {
        margin-top: 8px;
      }
      .info {
        display: flex;
        align-items: center;
        & + .info {
          margin-top: 10px;
          @media screen and(max-width:$tablet) {
            margin-top: 0px;
          }
        }
        @media screen and(max-width:$tablet) {
          &.pc {
            display: none;
          }
        }
        .title {
          font-size: 14px;
          font-weight: 800;
          color: #999999;
          @media screen and (max-width: $tablet) {
            display: none;
          }
        }
        .value {
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          margin-left: 10px;
          @media screen and(max-width:$tablet) {
            line-height: 1.5;
            font-size: 11px;
            margin: 0;
          }
        }
      }
    }
  }
}
