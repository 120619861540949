@import '../../styles/base/variable';
.image-viewer-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 9700;
  background: #000;

  &.image-viewer {
    &-enter {
      opacity: 0;
      transition: 0.3s ease;
    }
    &-enter-active {
      opacity: 1;
    }
    &-exit {
      opacity: 1;
      transition: 0.3s ease;
    }
    &-exit-active {
      opacity: 0;
    }
  }
  .info-box {
    position: absolute;
    bottom: 80px;
    left: 90px;
    text-align: left;

    @media screen and(max-width:$tablet) {
      left: 20px;
      bottom: 20px;
    }
    h2 {
      position: relative;
      font-size: 60px;
      font-weight: 800;
      color: #ffffff;
      z-index: 10;
      @media screen and(max-width:$tablet) {
        font-size: 30px;
      }
    }
    p {
      position: relative;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      opacity: 0.5;
      margin-top: 10px;
      z-index: 10;
      @media screen and(max-width:$tablet) {
        font-size: 13px;
        opacity: 1;
        margin-top: 4px;
      }
      &.date {
      }
    }
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.image-viewer-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 77px;
  height: 77px;
  background-size: 38px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000;
  @media screen and(max-width:$tablet) {
    width: 35px;
    height: 35px;
    background-size: 26px;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 280px;
    transform: rotate(-180deg);
    opacity: 0.5;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    z-index: 5;
    @media screen and(max-width:$tablet) {
      height: 120px;
    }
  }
}
.swiper-slide .image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  @media screen and(max-width:$tablet) {
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
  }
  &:hover {
    cursor: default;
  }
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    @media screen and(max-width:$tablet) {
      height: auto;
      width: 100%;
    }
  }
}
