@import '../../styles/base/variable';
.news-container {
  padding-top: 180px;
  .title {
    font-size: 60px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
}
.news-wrapper {
  position: relative;
  max-width: 1200px;
  margin: 95px auto 202px;
  @media screen and(max-width:$tablet) {
    margin: 40px 0 60px 0;
  }
  .news-item {
    cursor: pointer;
    .name {
      font-size: 26px;
      font-weight: 800;
      color: #222222;
      margin-top: 16px;
      @media screen and(max-width:$desk) {
        font-size: 16px;
      }
      @media screen and(max-width:$tablet) {
        margin-top: 8px;
        font-size: 14px;
      }
    }
    .model {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      margin-top: 6px;
      @media screen and(max-width:$tablet) {
        display: none;
      }
    }
    .date {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      margin-top: 6px;
      @media screen and(max-width:$tablet) {
        display: none;
      }
    }
    img {
      width: 100%;
    }
  }
}
