$menuHover: rgba(255, 255, 255, 0.7);
.header {
  position: absolute;
  height: 110px;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 9500;
  background-color: #fff;
  @media screen and (max-width: $tablet) {
    height: 44px;
    padding: 0;
  }
  &.fixed {
    position: fixed;
    transform: translate3d(0, 0px, 0);
    transition: all 0.3s ease;
    & + .btn-menu-wrap {
      transition: all 0.3s ease;
    }
    &.hide {
      transform: translate3d(0, -110px, 0);
      & + .btn-menu-wrap {
        transform: translate3d(0, -110px, 0);
      }
    }
    &.up {
      background-color: #ffffff;
    }
    @media screen and (max-width: $tablet) {
      transform: translate3d(0, 0px, 0);
      & + .btn-menu-wrap {
        transform: translate3d(0, 0px, 0);
      }
      &.hide {
        transform: translate3d(0, -44px, 0);
        & + .btn-menu-wrap {
          transform: translate3d(0, -44px, 0);
        }
      }
    }
  }
  &.detail {
    @media screen and (max-width: $tablet) {
      background-color: transparent;
      & + .btn-menu-wrap {
        .hamberger {
          span {
            background-color: #fff;
          }
        }
      }
      &.up,
      &.menu {
        & + .btn-menu-wrap {
          .hamberger {
            span {
              background-color: #222;
            }
          }
        }
      }
    }

    &.up,
    &.menu {
      .step_1 {
        color: #000 !important;
      }
      .step_2 {
        color: #aaa !important;
        opacity: 1 !important;
      }
      svg path {
        @media screen and (max-width: $tablet) {
          fill: #222 !important;
        }
      }
    }
    .logo {
      .text {
        display: none;
      }
      a {
        svg path {
          @media screen and (max-width: $tablet) {
            fill: #fff;
          }
        }
      }
    }
    .nav-text-wrap {
      svg path {
        @media screen and (max-width: $tablet) {
          fill: #fff;
        }
      }
    }
  }

  .nav-text-wrap {
    position: absolute;
    left: 97px;
    display: flex;
    align-items: center;

    @media screen and (max-width: $tablet) {
      left: 46px;
    }
    svg {
      margin: 0 8px;
      @media screen and (max-width: $tablet) {
        width: 6px;
        margin: 0 6px;
      }
      path {
        fill: #222222;
        transition: all 0.3s ease;
      }
    }
    .step {
      font-weight: 500;
      font-size: 14px;
      @media screen and (max-width: $tablet) {
        font-size: 12px;
        color: #fff;
      }
      &.step_1 {
        cursor: pointer;
      }
      &.step_2 {
        color: #aaa;
        @media screen and (max-width: $tablet) {
          opacity: 0.5;
          color: #fff;
        }
      }
    }
  }
}
.nav-items-wrap {
  .logo {
    position: fixed;
    top: 0;
    left: 0;
    height: 110px;
    @media screen and(max-width:$tablet) {
      height: 44px;
    }
  }
}
.logo {
  position: relative;
  padding-left: 42px;
  height: 38px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $tablet) {
    padding-left: 20px;
  }
  @media screen and (max-width: $tablet) {
    padding-left: 12px;
  }
  a {
    display: inline-block;
    width: 38px;
    height: 38px;
    font-size: 0;
    @media screen and (max-width: $tablet) {
      width: 26px;
      height: 26px;
    }
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: #222222;
        transition: all 0.3s ease;
      }
    }
    &:hover {
      & + .text {
        opacity: 1;
        @media screen and(max-width:$tablet) {
          opacity: 0;
        }
      }
    }
  }
  .text {
    width: 250px;
    height: 20px;
    margin-left: 20px;
    background-position: left center;
    background-size: 230px;
    background-repeat: no-repeat;
    background-image: url(../../images/logo-text.svg);
    opacity: 0;
    transition: opacity 0.3s;
  }
}
.btn-menu-wrap {
  position: fixed;
  width: 38px;
  height: 38px;
  top: 36px;
  right: 39px;
  margin: auto 0;
  z-index: 9900;
  cursor: pointer;
  @media screen and(max-width:$tablet) {
    top: 9px;
    right: 12px;
    width: 26px;
    height: 26px;
  }
  &:hover {
    .hamberger {
      span {
        background-color: #fff;

        @media screen and (max-width: $tablet) {
          background-color: #222;
        }
      }
    }
  }
  @media screen and (max-width: $tablet) {
    right: 12px;
    width: 26px;
    height: 26px;
  }
  .btn-menu {
    width: 100%;
    height: 100%;
  }
  .hamberger {
    position: absolute;
    top: 0;
    right: 0px;
    width: 100%;
    height: 100%;
    padding: 9px 5.8px;
    box-sizing: border-box;
    cursor: pointer;
    @media screen and (max-width: $tablet) {
      padding: 5px 4px;
    }
    &.active {
      &:hover {
        span {
          @media screen and (max-width: $desk) {
            background-color: #fff;
          }
          @media screen and (max-width: $tablet) {
            background-color: #222;
          }
        }
      }
      span {
        background-color: #fff;
        @media screen and (max-width: $desk) {
          background-color: #222;
        }
        &:nth-child(2) {
          width: 0%;
        }
        &.end {
          &:nth-child(1) {
            transform: translateY(9px) rotate(45deg);
            @media screen and (max-width: $tablet) {
              transform: translateY(7px) rotate(45deg);
            }
          }
          &:nth-child(3) {
            transform: translateY(-10px) rotate(-45deg);
            @media screen and (max-width: $tablet) {
              transform: translateY(-7px) rotate(-45deg);
            }
          }
        }
      }
    }
    span {
      position: relative;
      top: 0;
      display: block;
      width: 100%;
      height: 3px;
      background-color: #222;
      transform-origin: center;
      border-radius: 3px;
      margin-top: 6px;
      transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      @media screen and (max-width: $tablet) {
        height: 2px;
        margin-top: 5px;
        background-color: #222;
      }
      &:nth-child(1) {
        margin: 0;
      }
    }
  }
}
.header-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 5000;
  visibility: hidden;
  opacity: 0;
  &.menu {
    &-enter {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &-enter-active {
      visibility: visible;
      opacity: 1;
    }
    &-enter-done {
      visibility: visible;
      opacity: 1;
    }
    &-exit {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s $easeOut;
      transition-delay: 0.3s;
    }
    &-exit-active {
      visibility: hidden;
      opacity: 0;
    }
    &-exit-done {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.nav-items-wrap {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 9600;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  &.menu {
    &-enter {
      visibility: hidden;
      opacity: 0;
      transition: transform 0.5s $easeOut, opacity 0.3s ease;
      .nav-items li a {
        transform: translate3d(0, 50px, 0);
        opacity: 0;
        transition: none;
      }
    }
    &-enter-active {
      visibility: visible;
      opacity: 1;
      .nav-items li a {
        transform: translate3d(0, 0px, 0);
        transition: transform 0.4s $easeOut, opacity 0.2s ease;
        opacity: 1;
      }
    }
    &-enter-done {
      visibility: visible;
      opacity: 1;
      .nav-items li a {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
      }
    }
    &-exit {
      visibility: visible;
      opacity: 1;
      transition: all 0.4s $easeOut;
    }
    &-exit-active {
      visibility: hidden;
      opacity: 0;
    }
    &-exit-done {
      visibility: hidden;
      opacity: 0;
    }
  }
  .nav-items {
    padding-left: 20%;
    min-width: 50%;
    width: 50%;
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
    li {
      overflow: hidden;
      & + li {
        margin-top: 50px;
        @media screen and(max-width:$tablet) {
          margin-top: 30px;
        }
      }
      a {
        position: relative;
        display: inline-block;
        font-size: 60px;
        font-weight: 800;
        color: #000;
        padding-bottom: 8px;
        transition: transform 0.4s $easeOut, opacity 0.2s ease;
        @media screen and (max-width: $tablet) {
          font-size: 30px;
        }
        &:hover,
        &.active {
          &:before {
            width: 100%;
            left: 0;
            @media screen and(max-width:$tablet) {
              width: 0;
            }
          }
        }
        &:before {
          content: '';
          position: absolute;
          height: 8px;
          bottom: 0px;
          right: 0;
          width: 0;
          background-color: #222;
          transition: width 0.4s ease;
        }
      }
    }
  }
}
.header-image-wrap {
  position: fixed;
  top: 0;
  min-width: 50%;
  width: 50%;
  height: 100%;
  right: 0;
  visibility: hidden;
  z-index: 9600;
  transition: all 0.4s $easeOut;
  @media screen and(max-width:$desk) {
    display: none;
  }
  .btn-close {
    position: absolute;
    right: 27px;
    top: 24px;
    width: 50px;
    height: 50px;
    background-image: url(../../images/btn-close.svg);
    background-size: 38px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .menu {
    width: 100%;
    height: 100%;
    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &.move {
  }
  &.menu {
    &-enter {
      visibility: hidden;
      clip: rect(0, 50vw, 100vh, 50vw);
    }
    &-enter-active {
      visibility: visible;
      clip: rect(0, 50vw, 100vh, 0vw);
    }
    &-enter-done {
      visibility: visible;
      clip: rect(0, 50vw, 100vh, 0vw);
    }
    &-exit {
      opacity: 1;
      visibility: visible;
    }
    &-exit-active {
      opacity: 0;
      visibility: hidden;
    }
    &-exit-done {
      opacity: 0;
      visibility: hidden;
    }
  }
}
